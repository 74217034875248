import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DatenschutzComponent } from './component/datenschutz/datenschutz.component';
import { ImpressumComponent } from './component/impressum/impressum.component';
import { StartseiteComponent } from './component/startseite/startseite.component';

const routes: Routes = [
  { path: '', component: StartseiteComponent },
  { path: 'datenschutz', component: DatenschutzComponent },
  { path: 'impressum', component: ImpressumComponent },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
