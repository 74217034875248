import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer-block',
  templateUrl: './footer-block.component.html',
  styleUrls: ['./footer-block.component.scss']
})
export class FooterBlockComponent {

  @Input() classes: string;

  constructor() { }

}
