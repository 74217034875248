import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-startseite',
  template: /*html*/`
    <app-hero-block
      [background]="headerBackgroundProperties"
      [logoSrc]="'/assets/images/wein-farmer-pfalz-logo.png'"
      [layout]="1"
      [logoSrcAlt]="'Weinfarmer Pflaz Emblem'"
    ></app-hero-block>
    <app-text-block
      [classes]="'bg-primary pb-2 pt-6'"
      [headerText]="'intro.headerText' | translate"
      [contentText]="'intro.contentText' | translate"
    ></app-text-block>
    <app-video-block
      [classes]="'bg-primary pt-2 pb-6'"
    ></app-video-block>
    <app-bottle-slide-block
    [bottles]="bottles"
    ></app-bottle-slide-block>
    <app-text-block
      [classes]="'py-3'"
      [headerText]="'contact.headerText' | translate"
      [contentText]="'contact.contentText' | translate"
    ></app-text-block>
  `,
  styleUrls: []
})
export class StartseiteComponent {

  public headerBackgroundProperties = {
    'background-image': 'url(/assets/images/wein-farmer-pfalz--hero-background.png)',
    'background-position': 'center bottom',
    'background-size': '100% auto',
    'background-repeat': 'no-repeat'
  };

  public bottles = [
    {
      url: '/assets/images/rose.png',
      alt: 'Flaschen von Weinfarmer'
    },
    {
      url: '/assets/images/grauburgunder.png',
      alt: 'Flaschen von Weinfarmer'
    },
    {
      url: '/assets/images/riesling.png',
      alt: 'Flaschen von Weinfarmer'
    }
  ]
  constructor( ) { }
}
