import { BrowserModule } from '@angular/platform-browser';
import {NgModule, APP_INITIALIZER} from '@angular/core';

import { TranslateService } from './translate.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Common (Bausteine)
import { HeroBlockComponent } from './common/hero-block/hero-block.component';
import { TextBlockComponent } from './common/text-block/text-block.component';
import { VideoBlockComponent } from './common/video-block/video-block.component';
import { FooterBlockComponent } from './common/footer-block/footer-block.component';
import { BottleSlideBlockComponent } from './common/bottle-slide-block/bottle-slide-block.component';

// Component (Seiten)
import { DatenschutzComponent } from './component/datenschutz/datenschutz.component';
import { ImpressumComponent } from './component/impressum/impressum.component';
import { StartseiteComponent } from './component/startseite/startseite.component';
import { LangSwitcherComponent } from './common/lang-switcher/lang-switcher.component';
import {HttpClientModule} from '@angular/common/http';
import { TranslatePipe } from './translate.pipe';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieLawModule } from 'angular2-cookie-law';

export function setupTranslateFactory(service: TranslateService): Function {
    return () => service.use('de');
}

@NgModule({
  declarations: [
    AppComponent,
    HeroBlockComponent,
    TextBlockComponent,
    VideoBlockComponent,
    FooterBlockComponent,
    BottleSlideBlockComponent,
    DatenschutzComponent,
    ImpressumComponent,
    StartseiteComponent,
    LangSwitcherComponent,
    TranslatePipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule, // BrowserAnimationsModule is required
    CookieLawModule // import Angular's CookieLaw modules
  ],
  providers: [
      TranslateService,
      {
          provide: APP_INITIALIZER,
          useFactory: setupTranslateFactory,
          deps: [ TranslateService ],
          multi: true
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
