import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-impressum',
  template: /*html*/`
    <app-hero-block
      [background]=""
      [logoSrc]="'/assets/images/wein-farmer-pfalz-logo.png'"
      [layout]="2"
      [logoSrcAlt]="'Weinfarmer Pflaz Emblem'"
    ></app-hero-block>
    <app-text-block
      [classes]="content[0].classes"
      [headerText]="content[0].headerText"
      [contentText]="content[0].contentText"
      [layout]="2"
    ></app-text-block>
  `,
  styleUrls: []
})
export class ImpressumComponent {

  public content = [
    {
      classes: 'py-3',
      headerText: 'Impressum',
      contentText: `
        <p><strong>Seitenbetreiber i.S.d. § 5 TMG:</strong></p> <p>Inhaber:&nbsp;<span style="line-height: 20.8px;">Sven & Daniel Stadler</span><br>
        Weinfarmer GmbH & Co KG<br>
        Hauptstrasse 59<br>
        76889 Dierbach Deutschland &nbsp;</p> <p>Tel.: <a href="tel:06340 274"> 06340 3860887</a><br>
        Fax: 06340&nbsp;5618<br>
        Email: <a href="mailto:info@weinfarmer.de" target="_blank">info@weinfarmer.de</a><br>
        USt-IdNr.: -</p> <p><strong>Zuständige Aufsichtsbehörde:</strong><br>
        Aufsichts- und Dienstleistungsdirektion<br>
        Kurfürstliches Palais<br>
        Willy-Brandt-Platz 3<br>
        54290 Trier<br>
        Telefon: 0651-94940<br>
        Fax: 0651-9494170<br>
        Email: <a href="mailto:poststelle@add.rlp.de">poststelle@add.rlp.de</a><br>
        Internet: <a href="http://www.add.rlp.de/" target="_blank">www.add.rlp.de</a></p> <p><strong>Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV:</strong><br> <span style="line-height: 20.8px;">Sven & Daniel Stadler</span>&nbsp;(Anschrift wie oben)</p>
      `,
    }
  ]

  constructor( ) { }
}
