import {Component, OnInit, Input} from '@angular/core';
import {TranslateService} from '../../translate.service';

@Component({
    selector: 'app-bottle-slide-block',
    templateUrl: './bottle-slide-block.component.html',
    styleUrls: ['./bottle-slide-block.component.scss']
})
export class BottleSlideBlockComponent implements OnInit {

    constructor(
        private translate: TranslateService
    ) { }

    @Input() headerText: string;
    @Input() contentText: string;
    @Input() classes: string;
    @Input() bottles: Array<string>;
    @Input() downloadPath = '/assets/downloads/';

    ngOnInit() {
    }

}
