import {Component, OnInit, HostListener, Input} from '@angular/core';

@Component({
    selector: 'app-hero-block',
    templateUrl: './hero-block.component.html',
    styleUrls: ['./hero-block.component.scss']
})
export class HeroBlockComponent {

    private orientation = 'portrait';
    private bufferOrientation = 'landscape';
    private minHeight: string;

    @Input() logoSrc: string;
    @Input() background: object;
    @Input() logoSrcAlt: string;
    @Input() layout = 1;

    @HostListener('window:resize', ['$event.target'])
    onResize(win) {
        if (win.orientation !== undefined) {
            this.orientation = win.innerHeight > win.innerWidth ? 'portrait' : 'landscape';
            if (this.bufferOrientation !== this.orientation) {
                this.bufferOrientation = this.orientation;

                switch (this.orientation) {
                    case 'portrait':
                        this.minHeight = `${win.screen.height}px`;
                        break;
                    case 'landscape':
                        this.minHeight = `${win.screen.width}px`;
                        break;
                }
            }
        } else {
            this.minHeight = `${win.innerHeight}px`;
        }
    }

    constructor() {
        this.onResize(window);
    }

    public scroll() {
        document.getElementById('target').scrollIntoView({behavior: 'smooth', block: 'start'});
    }
}