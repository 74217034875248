import { Component, OnInit } from '@angular/core';
import {TranslateService} from './../../translate.service';

@Component({
  selector: 'app-lang-switcher',
  templateUrl: './lang-switcher.component.html',
  styleUrls: ['./lang-switcher.component.scss']
})
export class LangSwitcherComponent implements OnInit {

    constructor(public translate: TranslateService) {
    }

    setLang(lang: string) {
        this.translate.use(lang);
    }

  ngOnInit() {
  }

}
